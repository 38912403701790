import React from 'react';

import styled, { css } from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { TimePickerRangeElem } from '../../common/time-picker-range';
import { TextElem } from '../../common/text';
import calendarWeekend from '../../asset/svg/button/calendar-weekend.svg';
import calendarWeekendActive from '../../asset/svg/button/calendar-weekend-active.svg';

import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';

export const Component: React.FC<{
  day: string;
  time?: [string, string];
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  handleUpdate: (startTime: string, endTime: string) => void;
  handleSetWeekend: () => void;
  isWeekend?: boolean;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isSuccess,
  handleUpdate,
  day,
  time,
  handleSetWeekend,
  isWeekend,
}) => {
  const isTimeValid = Array.isArray(time) && time[0] && time[1];
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="SETTINGS.CONSULTATION.SUCCESS" />
      )}
      <Container>
        <DayContainer>
          <TextElem size="semiSmall" color="textPrimary" tid={day} />
        </DayContainer>
        <TimePickerRangeElem
          disabled={isWeekend || isLoading}
          defaultValue={isTimeValid ? time : ['00:00', '00:00']}
          handleUpdate={handleUpdate}
        />
        <WeekendButton isWeekend={isWeekend} onClick={handleSetWeekend}>
          <ImgStyled
            src={isWeekend ? calendarWeekendActive : calendarWeekend}
            alt="calendar"
          />
        </WeekendButton>
      </Container>
    </>
  );
};

const ImgStyled = styled.img`
  width: ${Spacing(5)};
  height: ${Spacing(5)};
`;

const WeekendButton = styled.button<{ isWeekend?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT_OPACITY]};
  padding: ${Spacing(1)} 14px;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  ${({ isWeekend }) => {
    if (isWeekend) {
      return css`
        background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
      `;
    }
  }}
`;

const DayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing(1)} ${Spacing(5)};
  width: 100%;
  max-width: 132px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
`;

const Container = styled.div`
  display: flex;
  gap: ${Spacing(3)};
`;
