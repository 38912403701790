import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  PROPS_TYPE,
} from './constant';
import { Component } from './component';

import { validation } from '../../lib/validation';
import { FormikValues, useFormik } from 'formik';
import { required } from '../../lib/validation/service';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { actionTime } from '../order-item/action';
import { createManualAction, updateAction } from './action';
import { generateDateList } from '../../lib/util/generateDateList';
import { CONSULTATION_USER_MODULE_NAME } from '../consultation-user-item';
import { CONSULTATION_STATUS } from '../../data/consultation/constant';

export const Container: React.FC<PROPS_TYPE> = ({
  clientTimezone = 0,
  psychologistId,
  consultationId,
  currentDate,
  isPaid,
  status,
}) => {
  const queryClient = useQueryClient();
  const [selectedTime, setSelectedTime] = useState('');
  const [timeSelectFocus, setTimeSelectFocus] = useState(false);

  const [timeOption, setTimeOption] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const isChangeTimeDisabled = false;

  const handleSelectFocus = (value: boolean) => {
    setTimeSelectFocus(value);
  };

  const onSuccess = () => {
    queryClient.invalidateQueries(CONSULTATION_USER_MODULE_NAME);
  };

  const action = useMutation(updateAction, { onSuccess });
  const manualAction = useMutation(createManualAction, { onSuccess });

  const config = {
    [FORM_VALUE_ENUM.DATE]: [required],
    [FORM_VALUE_ENUM.TIME]: [required],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.DATE]: '',
    [FORM_VALUE_ENUM.TIME]: '',
  };

  const handleCreateManualPayment = () => {
    manualAction.mutate({ consultationId });
  };

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER | any) => {
      const date = new Date(`${values.date}T${values.time}:00Z`);

      return action.mutate({
        consultationId,
        payload: {
          ...values,
          date: date.toISOString(),
        },
      });
    },
  });

  const fetchTime = useQuery(
    'time',
    () =>
      actionTime({
        selectedDate: formik.values[FORM_VALUE_ENUM.DATE],
        psychologistId: psychologistId || '',
      }),
    {
      enabled: !!formik.values[FORM_VALUE_ENUM.DATE],
      onSuccess: (data: string[]) => {
        const option = data.map((hour) => {
          const utcTime = new Date(
            `${formik.values[FORM_VALUE_ENUM.DATE]}T${hour}:00Z`,
          );
          const localTime = new Date(
            utcTime.getTime() + clientTimezone * 3600000,
          );
          const localHour = String(localTime.getUTCHours()).padStart(2, '0');
          return { label: `${localHour}:00`, value: hour };
        });
        setTimeOption(option);
      },
    },
  );

  useEffect(() => {
    if (selectedTime && timeSelectFocus) {
      fetchTime.refetch();
    }
  }, [timeSelectFocus]);

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (status === CONSULTATION_STATUS.CANCEL) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isFieldDisabled = () => {
    if (status === CONSULTATION_STATUS.CANCEL) {
      return true;
    }

    if (fetchTime.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }

    if (manualAction.isError && !manualAction.isLoading && getErrorMessage()) {
      return true;
    }

    if (
      fetchTime.isError &&
      !fetchTime.isLoading &&
      !fetchTime.isRefetching &&
      fetchTime.isRefetchError &&
      getErrorMessage()
    ) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    const timeError: ACTION_ERROR_INTER = fetchTime.error as ACTION_ERROR_INTER;
    const manualError: ACTION_ERROR_INTER =
      manualAction.error as ACTION_ERROR_INTER;

    if (manualError) {
      return manualError.message;
    }

    if (error) {
      return error.message;
    }
    if (timeError) {
      return timeError.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    if (name === FORM_VALUE_ENUM.DATE) {
      formik.setFieldValue(FORM_VALUE_ENUM.TIME, '');
      setSelectedTime(e.value);
    }

    formik.setFieldValue(name, e.value);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const dateOption = generateDateList(clientTimezone);

  return (
    <Component
      isPaid={isPaid}
      handleCreateManualPayment={handleCreateManualPayment}
      isChangeTimeDisabled={isChangeTimeDisabled}
      isLoading={action.isLoading}
      isSuccess={action.isSuccess}
      currentDate={currentDate}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isTimeLoading={fetchTime.isLoading || fetchTime.isRefetching}
      handleSelectFocus={handleSelectFocus}
      timeOption={timeOption}
      timezone={clientTimezone}
      setValue={setValue}
      setFieldValue={setFieldValue}
      formik={formik}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      isFieldDisabled={isFieldDisabled}
      dateOption={dateOption}
    />
  );
};
