import React from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router';

import { PSYCHO_ITEM_DATA_INTER } from '../constant';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
} from '../../../theme/value';
import userPhoto from '../../../asset/svg/user/user-photo.svg';

export const Card: React.FC<{
  data: PSYCHO_ITEM_DATA_INTER;
  selectedCategory?: string | number;
}> = ({ data, selectedCategory }) => {
  const href = `/psycho-profile/${data.id}`;

  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      history.push(href);
    }
  };

  const aboutMeStr =
    data?.aboutMe && data?.aboutMe.length > 84
      ? `${data.aboutMe.substring(0, 83)}...`
      : data?.aboutMe;

  const price = selectedCategory
    ? data.category.filter((e) => e.category === selectedCategory)[0].price
    : Math.min(...data.category.map((obj) => obj.price));

  return (
    <AStyled href={href} onClick={onClick}>
      <Container>
        <ImgStyled src={data?.photo?.url ?? userPhoto} />
        <InfoContainer>
          <DescriptionContainer>
            <TextElem
              tid={data?.name}
              type="medium"
              size="main"
              color="textPrimary"
            />
            <AboutMeWrapper>
              <TextElem
                tid={aboutMeStr || ''}
                type="light"
                color="textSecondary"
                size="input"
              />
            </AboutMeWrapper>
            <ChipContainer>
              {data.age && (
                <Chip>
                  <TextElem
                    tid="PSYCHO_LIST.LIST.AGE"
                    tvalue={{ value: data?.age || '' }}
                    type="regular"
                    size="small"
                    color="textSecondary"
                  />
                </Chip>
              )}
              {data.lgbtFrendly && (
                <Chip>
                  <TextElem
                    tid="PSYCHO_LIST.LIST.LGBT_FRENDLY"
                    type="regular"
                    size="small"
                    color="textSecondary"
                  />
                </Chip>
              )}
              {data.timezone && (
                <Chip>
                  <TextElem
                    tid={data.timezone}
                    type="regular"
                    size="small"
                    color="textSecondary"
                  />
                </Chip>
              )}
            </ChipContainer>
          </DescriptionContainer>
          <TextElem
            size="main"
            type="medium"
            color="textPrimary"
            tvalue={{ value: price }}
            tid={
              selectedCategory
                ? 'PSYCHO_LIST.LIST.PRICE'
                : 'PSYCHO_LIST.LIST.EMPTY_CATEGORY_PRICE'
            }
          />
        </InfoContainer>
      </Container>
    </AStyled>
  );
};

const AboutMeWrapper = styled.div`
  max-height: 50px;
  overflow: hidden;
  hyphens: auto;
  text-overflow: ellipsis;
`;

const AStyled = styled.a`
  text-decoration: none;
  width: 100%;
  max-width: 530px;

  @media screen and (max-width: 1389px) {
    max-width: unset;
  }
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacing(2)};
`;

const ImgStyled = styled.img`
  height: ${Spacing(45)};
  width: 100%;
  max-width: ${Spacing(45)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  object-fit: cover;
`;

const Chip = styled.div`
  padding: ${Spacing(2)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  width: max-content;
`;

const InfoContainer = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${Spacing(5)};
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  padding: ${Spacing(5)};
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: ${Spacing(4)};
  cursor: pointer;
  transition: ${VALUE_TRANSITION_DATA[VALUE_OPACITY_ENUM.HOVER]};

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;
