import React from 'react';

import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../theme/size';
import { TextElem } from '../../common/text';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { USER_ROLE } from '../../data/auth/constant';
import { CopyElem } from '../../common/copy';
import copyIcon from '../../asset/svg/alert/copy.svg';
import { ButtonElem } from '../../common/button';
import successChip from '../../asset/svg/common/tick-circle.svg';
import errorChip from '../../asset/svg/common/close-circle.svg';

export const Component: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  role?: USER_ROLE;
  isStartConsultation: boolean;
  isAfterConsultation: boolean;
  isTimeSuccess: boolean;
  url: string;
  isCompleteClient: boolean;
  isCompletePsycho: boolean;
  handleSuccess: () => void;
  isPaid?: boolean;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isSuccess,
  role,
  isStartConsultation,
  isAfterConsultation,
  url,
  isTimeSuccess,
  isCompleteClient,
  isCompletePsycho,
  handleSuccess,
  isPaid,
}) => {
  const isPsychoButton = role === USER_ROLE.PSYCHOLOGIST;
  const isClientButton = role === USER_ROLE.USER;
  const textUserAnswer = 'CONSULTATION.CONFIRM.USER_ANSWER';

  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="CONSULTATION.CONFIRM.SUCCESS" />
      )}
      {isTimeSuccess && isPaid && (
        <Double>
          <Block>
            {!isStartConsultation && !isAfterConsultation && (
              <InfoMessage>
                <TextElem
                  tid="CONSULTATION.NOT_START"
                  size="semiSmall"
                  color="textSecondary"
                />
              </InfoMessage>
            )}
            {isStartConsultation && !isAfterConsultation && isPaid && (
              <>
                <TextElem
                  size="input"
                  color="textSecondary"
                  tid="CONSULTATION.URL_TITLE"
                />
                <CopyElem value={url}>
                  <LinkMessage>
                    <TextElem tid={url} size="semiSmall" color="default" />
                    <CopyIconStyled src={copyIcon} alt="copy" />
                  </LinkMessage>
                </CopyElem>
              </>
            )}

            {isAfterConsultation && (
              <ConfirmContainer>
                <TextElem
                  tid="CONSULTATION.CONFIRM.TITLE"
                  color="textPrimary"
                  size="semiSmall"
                />
                <ButtonContainer>
                  {!isCompletePsycho ? (
                    isPsychoButton ? (
                      <ButtonElemStyled
                        onClick={handleSuccess}
                        type="submit"
                        tid="CONSULTATION.CONFIRM.BUTTON"
                      />
                    ) : (
                      <ChipError>
                        <TextElem
                          oneLine
                          color="noSuccess"
                          size="semiSmall"
                          tid="CONSULTATION.CONFIRM.PSYCHOLOGIST_NO_ANSWER"
                        />
                        <img alt="success" src={errorChip} />
                      </ChipError>
                    )
                  ) : (
                    <ChipSuccess>
                      <TextElem
                        color="success"
                        size="semiSmall"
                        tid={
                          !isPsychoButton
                            ? 'CONSULTATION.CONFIRM.PSYCHOLOGIST_ANSWER'
                            : textUserAnswer
                        }
                      />
                      <img alt="success" src={successChip} />
                    </ChipSuccess>
                  )}
                  {!isCompleteClient ? (
                    isClientButton ? (
                      <ButtonElemStyled
                        onClick={handleSuccess}
                        tid="CONSULTATION.CONFIRM.BUTTON"
                      />
                    ) : (
                      <ChipError>
                        <TextElem
                          color="noSuccess"
                          oneLine
                          size="semiSmall"
                          tid="CONSULTATION.CONFIRM.USER_NO_ANSWER"
                        />
                        <img alt="success" src={errorChip} />
                      </ChipError>
                    )
                  ) : (
                    <ChipSuccess>
                      <TextElem
                        color="success"
                        size="semiSmall"
                        tid={
                          !isClientButton
                            ? 'CONSULTATION.CONFIRM.CLIENT_ANSWER'
                            : textUserAnswer
                        }
                      />
                      <img alt="success" src={successChip} />
                    </ChipSuccess>
                  )}
                </ButtonContainer>
              </ConfirmContainer>
            )}
          </Block>
        </Double>
      )}
    </>
  );
};

const ButtonElemStyled = styled(ButtonElem)`
  padding: ${Spacing(4)} ${Spacing(6)} !important;
  div {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500 !important;
      white-space: nowrap;
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
    }
  }
`;

const Chip = styled.div`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  padding: ${Spacing(3)} ${Spacing(8)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing(2)};
`;

const ChipSuccess = styled(Chip)`
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.SUCCESS]};
`;

const ChipError = styled(Chip)`
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.NO_SUCCESS]};
`;

const ButtonContainer = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};
`;

const ConfirmContainer = styled.div`
  display: flex;
  gap: ${Spacing(4)};
  align-items: start;
  flex-direction: column;
`;

const CopyIconStyled = styled.img`
  width: 16px !important;
  height: 16px !important;
`;

const InfoMessage = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  padding: 15px;

  span {
    line-height: 1em;
  }
`;

const LinkMessage = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  padding: 15px;

  span {
    text-decoration: underline;
    line-height: 1em;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${Spacing(4)};
  justify-content: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(6)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;
`;

const Double = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};
`;
