import React from 'react';
import styled from 'styled-components';

import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { ButtonElem } from '../../common/button';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../theme/value';
import tickSquare from '../../asset/svg/button/tick-square.svg';
import { TimePickerRangeElem } from '../../common/time-picker-range';
import { SelectElem } from '../../common/select';
import { WEEKEND_PAYLOAD_INTER } from './constant';
import addSvg from '../../asset/svg/common/add.svg';

export const Component: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  handleSuccess: () => void;
  isAddWeekend: boolean;
  handleAddForm: () => void;
  dateOption: { label: string; value: string }[];
  updatePayload: (name: keyof WEEKEND_PAYLOAD_INTER, value: string) => void;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isSuccess,
  handleSuccess,
  isAddWeekend,
  handleAddForm,
  dateOption,
  updatePayload,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="SCHEDULE.WEEKEND.SUCCESS" />
      )}
      <Card>
        <ButtonElemStyled
          padding="16px"
          iconRight={addSvg}
          iconSize="large"
          onClick={handleAddForm}
          disabled={isLoading || isAddWeekend}
          type="button"
          tid="SCHEDULE.WEEKEND.ADD_BUTTON"
        />
        {isAddWeekend && (
          <AddCardContainer>
            <SelectElem
              minHeight="48px"
              name="day"
              isDisabled={isLoading}
              dynamic
              placeholder="SCHEDULE.WEEKEND.DAY_LIST_PLACEHOLDER"
              onChange={(
                _name: string,
                value: { value: string; label: string },
              ) => {
                updatePayload('date', value.value);
              }}
              options={dateOption}
            />
            <TimePickerRangeElem
              disabled={isLoading}
              defaultValue={['00:00', '00:00']}
              handleUpdate={(startTime: string, endTime: string) => {
                updatePayload('startTime', startTime);
                updatePayload('endTime', endTime);
              }}
            />
            <WeekendButton onClick={handleSuccess}>
              <ImgStyled src={tickSquare} alt="calendar" />
            </WeekendButton>
          </AddCardContainer>
        )}
      </Card>
    </>
  );
};

const ButtonElemStyled = styled(ButtonElem)`
  div {
    justify-content: center;
  }
`;

const ImgStyled = styled.img`
  width: ${Spacing(5)};
  height: ${Spacing(5)};
`;

const WeekendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
  padding: ${Spacing(1)} 14px;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const AddCardContainer = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};
  grid-template-columns: 134px 1fr 48px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
`;
