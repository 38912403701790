import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import PhoneInput from 'react-phone-input-2';

import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { TextElem } from '../text';
import { PROPS_TYPE } from './constant';
import { i18n } from '../../lib/lang';

export const Elem: React.FC<PROPS_TYPE> = ({
  title,
  error,
  errorMessage,
  onChange,
  onBlur,
  value,
  name,
  type,
  disabled,
  icon,
  placeholder,
  className,
  maxLength,
  min,
  max,
  errorContainer = true,
  autoFocus = false,
  isDot = false,
}) => {
  const isSuccess = !!value && !error;

  const refIcon = useRef<HTMLDivElement>(null);
  const handleChange = (e: any) => {
    if (type === 'phone') {
      if (e && onChange && e.length < 14) {
        onChange({ target: { name: name, value: `+${e}` } });
      }
    } else {
      if (!e.target) e.target = {};
      e.target.name = name;
      e.target.value = maxLength
        ? e.target.value.substr(0, maxLength)
        : e.target.value;

      if (onChange) {
        onChange(e);
      }
    }
  };

  const handleBlur = (e: any) => {
    if (!e.target) e.target = {};

    e.target.name = name;
    e.target.value = e.target.value;

    if (onBlur) {
      onBlur(e);
    }
  };

  const iconSize = refIcon?.current?.clientWidth || 40;
  if (disabled) {
    return (
      <Container>
        {title && (
          <TitleContainer>
            <TextElem
              type="light"
              color="textSecondary"
              size="input"
              tid={title}
            />

            {isDot && <Dot />}
          </TitleContainer>
        )}
        <FakeInput>
          <TextElem
            color="textDisabled"
            tid={value || i18n.t(placeholder || '')}
          />
        </FakeInput>
      </Container>
    );
  } else {
    return (
      <Container className={className}>
        {title && (
          <TitleContainer>
            <TextElem
              type="light"
              color="textSecondary"
              size="input"
              tid={title}
            />

            {isDot && <Dot />}
          </TitleContainer>
        )}
        <Content>
          {type === 'phone' ? (
            <PhoneInputCustom
              isSuccess={isSuccess}
              country={'ua'}
              countryCodeEditable={false}
              onChange={handleChange}
              onBlur={handleBlur}
              value={value}
              inputProps={{
                name: name,
              }}
            />
          ) : (
            <>
              <CustomInput
                isSuccess={isSuccess}
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={error}
                type={type}
                value={value}
                iconSize={iconSize}
                placeholder={placeholder && i18n.t(placeholder)}
                className={className}
                maxLength={maxLength}
                min={min}
                max={max}
                autoFocus={autoFocus}
              />
              <div id="content" />
              <Icon ref={refIcon}>{icon && icon}</Icon>
            </>
          )}

          {errorContainer && (
            <>
              {(error || errorMessage) && (
                <ErrorContainer className="errorContainer">
                  <TextElem size="alert" color="error">
                    {errorMessage}
                  </TextElem>
                </ErrorContainer>
              )}
            </>
          )}
        </Content>
      </Container>
    );
  }
};

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const CustomInput = styled.input<{
  error?: boolean;
  iconSize: number;
  isSuccess: boolean;
}>`
  cursor: pointer;
  text-overflow: ellipsis;

  font-family: 'e-Ukraine' !important;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.LIGHT]};
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  line-height: 1em;
  text-align: start;
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
  padding: ${Spacing(4)};
  padding-right: ${({ iconSize }) => iconSize}px;
  min-height: 54px;

  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  width: 100%;
  transition: background 0.2s;
  outline: none;

  :focus-visible {
    outline: none;
  }

  :focus {
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
  }

  &:focus-within {
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  }

  &:hover:not(:focus) {
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

    ::placeholder {
      display: none;
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    }
  }

  ::placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ error }) => error && css``}
`;

// PhoneInputCustom стили
const PhoneInputCustom = styled(PhoneInput)<{ isSuccess: boolean }>`
  font-family: 'e-Ukraine' !important;

  && div {
    display: none;
  }

  && input {
    padding: 16px 20px;
    min-height: 54px;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.LIGHT]};
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
    line-height: 1.4em;
    text-align: start;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;
    width: 100%;
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
    outline: none;

    ::placeholder {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
    }

    :focus-visible {
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
      background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
      outline: none;
    }

    &:hover:not(:focus) {
      background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

      ::placeholder {
        display: none;
        color: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
      }
    }
  }
`;

const FakeInput = styled.div`
  padding: 16px 20px;
  position: relative;
  height: 54px;
  font-size: ${({ theme }) => theme[SIZE_FONT_ENUM.INPUT]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_DISABLED]};
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  line-height: 1em;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;
  width: 100%;
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};

  overflow: hidden;

  display: flex;
  align-items: center;

  && > span {
    width: calc(100% - 40px);
    display: block;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
`;

const Content = styled.div`
  position: relative;
`;

const ErrorContainer = styled.div`
  height: 13.19px;
`;

const Container = styled.div`
  display: grid;
  width: 100%;

  grid-gap: ${Spacing(3)};
  input:-webkit-autofill,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]} inset;
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  }

  :hover {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]} !important;

      -webkit-box-shadow: 0 0 0px 1000px
        ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]} inset;
    }
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: max-content;
  box-sizing: content-box;
  z-index: 2;
`;
