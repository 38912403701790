import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import { FieldTextElem } from '../../common/field-text';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { FileUploaderElem } from '../../common/file-uploader';
import { FormElem } from '../../common/form';
import { LoaderElem } from '../../common/loader';
import { SelectElem } from '../../common/select';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import {
  CATEGORY_ENUM,
  CATEGORY_OPTION_LIST,
  FORM_VALUE_ENUM,
} from '../profile-psycho-create-category/constant';
import { CATEGORY_VIEW } from '../profile-psycho/constant';
import { FILE_UPLOAD_INTER } from '../../data/file/constant';
import EditSvg from '../../asset/svg/button/edit.svg';

export const Component: React.FC<{
  data: any;
  onToggle: () => void;
  isOpen: boolean;
  isLoading?: boolean;
  setFieldValue: Function;
  getFieldError: Function;
  isFieldError: Function;
  setValue: Function;
  getFieldValue: Function;
  formik: FormikValues;
  handleSubmit: Function;
  isSubmitDisabled?: boolean;
  isError?: boolean;
  getErrorMessage: Function;
  isSuccess?: boolean;
  onDeleteVideo: Function;
  setVideoState: (e: File | FILE_UPLOAD_INTER) => void;
  addDiplomaCount: number;
  setAddDiplomaCount: React.Dispatch<React.SetStateAction<number>>;
  addCertificateCount: number;
  setAddCertificateCount: React.Dispatch<React.SetStateAction<number>>;
  setDiplomaState: Function;
  onDeleteDiploma: Function;
  setCertificateState: Function;
  onDeleteCertificate: Function;
  handleDelete: Function;
  isSuccessDelete: boolean;
  isAddDiplomaDisabled: boolean;
  isAddCertificateDisabled: boolean;
}> = ({
  data,
  onToggle,
  isOpen,
  isLoading,
  setFieldValue,
  getFieldError,
  isFieldError,
  setValue,
  getFieldValue,
  formik,
  handleSubmit,
  isSubmitDisabled,
  isError,
  getErrorMessage,
  isSuccess,
  onDeleteVideo,
  setVideoState,
  addDiplomaCount,
  setAddDiplomaCount,
  addCertificateCount,
  setAddCertificateCount,
  setDiplomaState,
  onDeleteDiploma,
  setCertificateState,
  onDeleteCertificate,
  handleDelete,
  isSuccessDelete,
  isAddDiplomaDisabled,
  isAddCertificateDisabled,
}) => {
  return (
    <>
      {isSuccessDelete && (
        <AlertActionElem type="success" tid="USER.PSYCHOLOGIST.DELETE" />
      )}
      {isSuccess && (
        <AlertActionElem type="success" tid="CATEGORY.FORM.SUCCESS" />
      )}
      {isLoading && <LoaderElem />}
      {!isOpen && (
        <CardContainer onClick={onToggle}>
          <TextElem
            type="medium"
            size="semiSmall"
            color="textPrimary"
            tid={CATEGORY_VIEW[data?.category as CATEGORY_ENUM]}
          />
          <img src={EditSvg} alt="edit" />
        </CardContainer>
      )}
      {isOpen && (
        <Container onSubmit={() => {}}>
          <SelectElem
            isDot
            placeholder="USER.PSYCHOLOGIST.CATEGORY_PLACEHOLDER"
            name={FORM_VALUE_ENUM.CATEGORY}
            onChange={setFieldValue}
            options={CATEGORY_OPTION_LIST}
            title="USER.PSYCHOLOGIST.CATEGORY_NAME"
            errorMessage={getFieldError(FORM_VALUE_ENUM.CATEGORY)}
            error={isFieldError(FORM_VALUE_ENUM.CATEGORY)}
            value={setValue(CATEGORY_OPTION_LIST, FORM_VALUE_ENUM.CATEGORY)}
          />
          <FieldTextElem
            isDot
            name={FORM_VALUE_ENUM.PRICE}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="USER.PSYCHOLOGIST.PRICE"
            placeholder="USER.PSYCHOLOGIST.PRICE_PLACEHOLDER"
            value={getFieldValue(FORM_VALUE_ENUM.PRICE)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.PRICE)}
            error={isFieldError(FORM_VALUE_ENUM.PRICE)}
            type="number"
          />
          <FieldTextAreaElem
            name={FORM_VALUE_ENUM.DESCRIPTION}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
            title="USER.PSYCHOLOGIST.DESCRIPTION"
            placeholder="USER.PSYCHOLOGIST.CATEGORY.DESCRIPTION_PLACEHOLDER"
            errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
            error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
          />
          <FileUploaderElem
            title="COMMON.FILE.VIDEO_TITLE"
            onFileUpload={(e) => {
              setVideoState(e.file);
            }}
            onRemove={onDeleteVideo}
            fileObj={data?.video}
            uploadType="video"
            dropZoneTitle="COMMON.FILE.VIDEO_CONTENT_TITLE"
          />
          <FileButtonContainer>
            {data?.diploma?.length > 0 &&
              data.diploma.map((e: File | any, index: number) => (
                <FileUploaderElem
                  key={e.id}
                  fileObj={e}
                  title={index == 0 ? 'COMMON.FILE.DIPLOMA_TITLE' : undefined}
                  onFileUpload={(e) => {
                    setDiplomaState(e);
                  }}
                  onRemove={onDeleteDiploma}
                  uploadType="document"
                  dropZoneTitle="COMMON.FILE.DIPLOMA_CONTENT_TITLE"
                />
              ))}
            {Array.from({ length: addDiplomaCount }).map((_, index) => (
              <FileUploaderElem
                title={
                  index === 0 && data.diploma.length === 0
                    ? 'COMMON.FILE.DIPLOMA_CONTENT_TITLE'
                    : ''
                }
                key={index}
                disabled={isLoading}
                onFileUpload={(e) => {
                  setDiplomaState(e);
                }}
                onRemove={onDeleteDiploma}
                uploadType="document"
                dropZoneTitle="COMMON.FILE.DIPLOMA_CONTENT_TITLE"
              />
            ))}
            <ButtonElem
              tid="COMMON.FILE.UPLOAD"
              disabled={isAddDiplomaDisabled}
              type="file"
              onClick={() => {
                if (addDiplomaCount < 6 && !isAddDiplomaDisabled) {
                  setAddDiplomaCount((prev) => prev + 1);
                }
              }}
            />
          </FileButtonContainer>
          <FileButtonContainer>
            {data?.certificate?.length > 0 &&
              data.certificate.map((e: File | any, index: number) => (
                <FileUploaderElem
                  key={e.id}
                  fileObj={e}
                  title={
                    index === 0 ? 'COMMON.FILE.CERTIFICATE_TITLE' : undefined
                  }
                  onFileUpload={(e) => {
                    setCertificateState(e);
                  }}
                  onRemove={onDeleteCertificate}
                  uploadType="document"
                  dropZoneTitle="COMMON.FILE.CERTIFICATE_CONTENT_TITLE"
                />
              ))}
            {Array.from({ length: addCertificateCount }).map((_, index) => (
              <FileUploaderElem
                title={
                  index === 0 && data.certificate.length === 0
                    ? 'COMMON.FILE.CERTIFICATE_CONTENT_TITLE'
                    : ''
                }
                key={index}
                disabled={isLoading}
                onFileUpload={(e) => {
                  setCertificateState(e);
                }}
                onRemove={onDeleteCertificate}
                uploadType="document"
                dropZoneTitle="COMMON.FILE.CERTIFICATE_CONTENT_TITLE"
              />
            ))}
            <ButtonElem
              tid="COMMON.FILE.UPLOAD"
              disabled={isAddCertificateDisabled}
              type="file"
              onClick={() => {
                if (addCertificateCount < 6 && !isAddCertificateDisabled) {
                  setAddCertificateCount((prev) => prev + 1);
                }
              }}
            />
          </FileButtonContainer>

          <TitleContainer>
            <Dot />
            <TextElem
              tid="USER.PSYCHOLOGIST.REQUIRED"
              type="light"
              size="input"
              color="textSecondary"
            />
          </TitleContainer>
          <ButtonContainer isDeleteFunc>
            <ButtonElem
              onClick={handleSubmit}
              type="submit"
              disabled={isSubmitDisabled}
              tid="CATEGORY.FORM.BUTTON"
            />

            <ButtonElem
              disabled={isLoading}
              onClick={handleDelete}
              type="save"
              tid="CATEGORY.FORM.DELETE"
            />
          </ButtonContainer>
          {isError && <AlertActionElem text={getErrorMessage()} />}
        </Container>
      )}
    </>
  );
};

const ButtonContainer = styled.div<{ isDeleteFunc: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ isDeleteFunc }) =>
    isDeleteFunc ? '1fr 1fr' : '1fr'};
  grid-gap: ${Spacing(6)};
`;

const FileButtonContainer = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
  align-self: start;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const Container = styled(FormElem)`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: 19px ${Spacing(5)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
