import React from 'react';

import { DATA_INTER } from '../constant';
import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import { ReadMoreElem } from '../../../common/read-more';

export const About: React.FC<{ data?: DATA_INTER }> = ({ data }) => {
  return (
    <InfoContainer>
      <TextContainer>
        <TextElemStyled
          type="medium"
          color="textPrimary"
          size="main"
          tid="USER.PSYCHO.PROFILE.ABOUT"
        />

        <ReadMoreElem
          textLimit={440}
          text={data?.aboutMe || 'USER.PSYCHO.PROFILE.NOT_SET'}
        />
      </TextContainer>
      <TextContainer>
        <TextElemStyled
          type="medium"
          color="textPrimary"
          size="main"
          tid="USER.PSYCHO.PROFILE.ABOUT_MY_PATH"
        />

        <ReadMoreElem
          textLimit={440}
          text={data?.aboutMyPath || 'USER.PSYCHO.PROFILE.NOT_SET'}
        />
      </TextContainer>
      <TextContainer>
        <TextElemStyled
          type="medium"
          color="textPrimary"
          size="main"
          tid="USER.PSYCHO.PROFILE.ABOUT_MY_SERVICE"
        />

        <ReadMoreElem
          textLimit={440}
          text={data?.aboutMyService || 'USER.PSYCHO.PROFILE.NOT_SET'}
        />
      </TextContainer>
    </InfoContainer>
  );
};

const TextElemStyled = styled(TextElem)`
  @media screen and (max-width: 900px) {
    font-size: 15px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};

  @media screen and (max-width: 900px) {
    gap: ${Spacing(3)};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  gap: ${Spacing(3)};
  padding: ${Spacing(7)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  span {
    line-height: 1.6em !important;
  }

  @media screen and (max-width: 900px) {
    padding: ${Spacing(5)};
  }
`;
