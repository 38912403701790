import React, { useState } from 'react';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { SkeletonProfileElem } from '../../common/skeleton-profile';
import { AlertActionElem } from '../../common/alert-action';
import { TabSecondaryElem } from '../../common/tab-secondary';
import { UserHeader } from './frame/user-header';
import { ConsultationReassignContainer } from '../consultation-reassign';
import { USER_ROLE } from '../../data/user/constant';
import {
  CONSULTATION_DATA_INTER,
  CONSULTATION_STATUS,
} from '../../data/consultation/constant';
import { PaymentLinkContainer } from '../payment-link';
import { tabListPsycho, tabListUser } from './constant';
import { ConsultationConfirmContainer } from '../consultation-confirm';
import { ClientContainer } from './frame/client';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { BackHeaderElem } from '../../common/back-header';

export const Component: React.FC<{
  data: CONSULTATION_DATA_INTER;
  role?: USER_ROLE;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
}> = ({
  data,
  isSuccess,
  isLoading,
  isError,
  errorMessage,
  role = USER_ROLE.USER,
}) => {
  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const timezone = auth.user ? auth.user.timezone : null;
  const isCancel = data.status === CONSULTATION_STATUS.CANCEL;

  const [active, setActive] = useState(1);
  return (
    <>
      {isLoading && <SkeletonProfileElem />}
      {isError && <AlertActionElem text={errorMessage} />}

      {isSuccess && (
        <>
          <BackHeaderElem id={data.id} />
          <Container>
            <UserHeader role={role} {...data} />
            <TabSecondaryElem
              isFullWith={false}
              tabList={role === USER_ROLE.USER ? tabListUser : tabListPsycho}
              active={active}
              setActive={setActive}
            />
            {active === 1 && (
              <>
                <Double>
                  <PaymentLinkContainer
                    role={role}
                    paymentId={data?.id}
                    price={data.price}
                    duration={data.duration}
                    isPaid={data?.payment?.isPaid}
                    isCancel={data.status === CONSULTATION_STATUS.CANCEL}
                  />
                  {!isCancel && (
                    <ConsultationReassignContainer
                      userTimezone={timezone}
                      clientTimezone={data?.user?.numberTimezone}
                      psychologistId={data?.psychologist.id}
                      startDate={data.date}
                      currentDate={{
                        timeView: data.timeView,
                        dayView: data.dayView,
                      }}
                      consultationId={data?.id}
                      role={role}
                    />
                  )}
                </Double>
                {!isCancel && (
                  <ConsultationConfirmContainer
                    isPaid={data?.payment?.isPaid}
                    role={role}
                    timezone={timezone}
                    consultationId={data.id}
                    url={data.psychologist.url ?? ''}
                    duration={data.duration}
                    isCompleteClient={data.isCompleteClient}
                    isCompletePsycho={data.isCompletePsycho}
                  />
                )}
              </>
            )}
            {active === 2 && (
              <ClientContainer
                {...data.user}
                category={data.category}
                comment={data.comment}
              />
            )}
          </Container>
        </>
      )}
    </>
  );
};

const Double = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};
`;

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(4)};
  margin-top: 38px;
`;
