import styled from 'styled-components';
import React from 'react';

import { Spacing } from '../../../theme/service';
import { COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text/index';
import { CONSULTATION_ITEM_DATA_INTER } from '../constant';
import { useHistory } from 'react-router';
import { ChipElem } from '../../../common/chip/index';
import { GridElem } from '../../../common/grid/index';
import {
  CHIP_STATUS_PAYMENT_COLOR_BG,
  CHIP_STATUS_PAYMENT_COLOR_TEXT,
} from '../../consultation-list/constant';

export const Card: React.FC<CONSULTATION_ITEM_DATA_INTER> = ({
  id,
  createDate,
  price,
  user,
  psychologist,
  category,
  date,
  time,
  paymentStatus,
  duration,
}) => {
  const href = `/consultation/${id}`;

  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      history.push(href);
    }
  };
  return (
    <CardStyled key={id} href={href} onClick={onClick}>
      <TextElem
        oneLine
        className="item"
        size="semiSmall"
        color="textPrimary"
        tid={createDate}
      />
      <GridStyled>
        <TextElem
          oneLine
          className="item"
          size="semiSmall"
          color="textPrimary"
          tid={date}
        />
        <TextElem
          oneLine
          className="item"
          size="semiSmall"
          color="textPrimary"
          tid="LANG.DATA.DATE_TVALUE"
          tvalue={{ date: time }}
        />
      </GridStyled>

      <TextElem
        oneLine
        className="item"
        size="semiSmall"
        color="textPrimary"
        tid={`${category}`}
      />
      <GridStyled className="item">
        <TextElem
          size="semiSmall"
          color="default"
          tid="CONSULTATION.PRICE_TVALUE"
          tvalue={{ price }}
          oneLine
        />
        <TextElem oneLine size="semiSmall" color="textPrimary" tid={duration} />
      </GridStyled>
      <TextElem
        oneLine
        className="userEmail"
        size="semiSmall"
        color="textPrimary"
        tid={user.email}
      />
      <TextElem
        oneLine
        className="psychoEmail"
        size="semiSmall"
        color="textPrimary"
        tid={psychologist.email}
      />
      <Chip
        className=" paymentStatus"
        tid={`CONSULTATION.PAYMENT.${paymentStatus}`}
        txColor={CHIP_STATUS_PAYMENT_COLOR_TEXT[paymentStatus]}
        bgColor={CHIP_STATUS_PAYMENT_COLOR_BG[paymentStatus]}
      />
    </CardStyled>
  );
};

const CardStyled = styled.a<{}>`
  width: 100%;
  max-width: 1068px; // ширина по дизайну
  display: grid;
  grid-column: span 7;
  // grid-template-columns: subgrid;
    // grid-template-columns: 107px 137px 122px 91px 126px 126px 112px;
    grid-template-columns: 107px 137px 122px 134px 126px 126px 112px;
    gap: ${Spacing(7)};
  // grid-template-columns: repeat(7, 1fr) ;
  justify-content: start;
  position: relative;
  align-items: center;
  // gap: ${Spacing(4)};
  gap: auto;
  padding: ${Spacing(4)} ${Spacing(5)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: 14px;
  height: 56px;
  text-decoration: none;

  @media screen and (max-width: 900) {
    grid-column: 1;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: center;
    height: 110px;
    align-items: center;
    gap: ${Spacing(3)};
    width: 100%;

    & > .paymentStatus {
      position: absolute;
      top: ${Spacing(4)};
      right: ${Spacing(4)};
    }
  }
`;

const Chip = styled(ChipElem)<{ bgColor: string; txColor: string }>`
  min-width: max-content;
  background: ${({ bgColor }) => bgColor && bgColor} !important;
  span {
    color: ${({ txColor }) => txColor && txColor} !important;
  }

  padding: ${Spacing(1.5)} ${Spacing(3)};
`;

const GridStyled = styled.span`
  display: flex;
  gap: ${Spacing(4)};
`;
