import React from 'react';
import { TimePicker } from 'antd';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';

import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import ArrowIcon from '../../asset/svg/common/arrow.svg';
import { ReactComponent as CalendarIcon } from '../../asset/svg/common/calendar-time-picker.svg';

export const Elem: React.FC<{
  handleUpdate: any;
  defaultValue: [string, string];
  disabled?: boolean;
}> = ({ defaultValue, handleUpdate, disabled }) => {
  const format = 'HH:mm';

  const handleTimeChange = (times: [Dayjs | null, Dayjs | null] | null) => {
    if (times && times[0] && times[1]) {
      const formattedTimes = times.map((time) => time?.format('HH:mm'));

      if (formattedTimes[0] && formattedTimes[1]) {
        handleUpdate(formattedTimes[0], formattedTimes[1]);
      }
    }
  };

  return (
    <Container id="container" className="timeRange">
      <TimePickerStyled
        disabled={disabled}
        onChange={handleTimeChange}
        defaultValue={[
          dayjs(defaultValue[0], format),
          dayjs(defaultValue[1], format),
        ]}
        format={format}
        suffixIcon={<CalendarIcon />}
      />
    </Container>
  );
};

const Container = styled.div``;

const TimePickerStyled = styled(TimePicker.RangePicker)`
  box-sizing: border-box;

  .calendar-popup.ant-picker-range-arrow {
    display: none;
  }
  .calendar-popup.ant-picker-panel {
  }

  .ant-picker-range-separator {
    svg {
      path {
        d: path('M20 12H4M4 12L8.75676 6M4 12L8.75676 18');
      }
    }
    position: relative;
    ::after {
      position: absolute;
      display: block;
      content: '';
      height: 14px;
      width: 18px;
      background: url(${ArrowIcon});
      top: 1px;
      left: 5px;
    }
  }

  cursor: pointer;
  height: 48px;
  :focus-visible {
    outline: none;
  }
  position: relative;
  .ant-picker-active-bar {
    display: none;
  }
  box-shadow: none !important;
  .ant-picker-input > input {
    ::placeholder {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
      font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.LIGHT]};
    }
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
  }

  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};

  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
  @media screen and (width<=600px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
  }
  line-height: 1em;
  text-align: start;

  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;
  width: 100%;

  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;

  &:hover {
    /* border-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]}; */
    background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]} !important;

    .ant-picker-input > input {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;

      ::placeholder {
        color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
      }
    }
  }

  &:focus-within {
    background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  }

  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: ${Spacing(2)} ${Spacing(4)};
`;
