import React from 'react';
import { useQuery } from 'react-query';

import {
  ACTION_ERROR_INTER,
  MODULE_NAME,
  SCHEDULE_RAW_INTER,
} from './constant';
import { Component } from './component';
import { getDateAction } from './action';

export const Container: React.FC = () => {
  const preFetch = useQuery([MODULE_NAME], getDateAction);
  const data = preFetch.data as unknown as SCHEDULE_RAW_INTER;

  const isSubmitDisabled = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
    if (getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  return (
    <Component
      data={data}
      isLoading={preFetch.isLoading}
      isSuccess={preFetch.isSuccess}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};
