import React from 'react';

import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { ScheduleItemContainer } from '../schedule-item';
import { SCHEDULE_LIST, SCHEDULE_RAW_INTER } from './constant';
import { TextElem } from '../../common/text';

export const Component: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  data: SCHEDULE_RAW_INTER;
}> = ({ isLoading, isError, errorMessage, isSuccess, data }) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      <Container>
        <TitleContainer>
          <TextElem
            type="medium"
            size="main"
            color="textPrimary"
            tid="SCHEDULE.WORKING.TITLE"
          />
          <TextElem
            type="light"
            size="small"
            color="textSecondary"
            tid="SCHEDULE.WORKING.DESCRIPTION"
          />
        </TitleContainer>

        {isSuccess && (
          <>
            {SCHEDULE_LIST.map((e) => {
              const scheduleItem = data?.schedule && data?.schedule[e?.id];

              return (
                <ScheduleItemContainer
                  key={e.id}
                  id={e.id}
                  time={scheduleItem?.time}
                  day={e.tid}
                  isWeekendDay={scheduleItem?.isWeekend}
                />
              );
            })}
          </>
        )}
      </Container>
    </>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  margin-bottom: ${Spacing(3)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.ALERT]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};
`;
