import React from 'react';

import styled, { css } from 'styled-components';

import {
  CONSULTATION_STATUS_OPTION,
  FORM_VALUE_ENUM,
  PAYMENT_STATUS_OPTION,
} from './constant';
import { SelectElem } from '../../common/select';
import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import { SIZE_LAYOUT_DATA, SIZE_LAYOUT_ENUM } from '../../theme/size';
import { FieldDateInputElem } from '../../common/field-data-input/index';
import { i18n } from '../../lib/lang/core';
import consultationIcon from '../../asset/svg/menu-app/consultation.svg';
import { COLOR_ENUM } from '../../theme/color';

export const Component: React.FC<{
  isFieldError: Function;
  getFieldError: Function;
  onChangeSelect: (name: string, values: any) => void;
  handleSelectUserChange: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  setFieldValue: Function;
  setValue: Function;
  setDate: Function;
  date: [string, string] | [];
  setIndentify: Function;
  isOpenFilter?: boolean;
  toggleOpenFilter: Function;
  userList: {
    label: string;
    value: string;
  }[];
}> = ({
  isFieldError,
  getFieldError,
  onChangeSelect,
  getFieldValue,
  isLoading,
  setFieldValue,
  handleSelectUserChange,
  setValue,
  setDate,
  setIndentify,
  date,
  userList,
  isOpenFilter,
  toggleOpenFilter,
}) => {
  const multiValueContainer = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(
      (selected: any) => selected.label === label,
    );
    const isLastSelected = index === allSelected?.length - 1;
    const labelSuffix = isLastSelected ? '' : ', ';
    const val = `${label}${labelSuffix}`;
    return val;
  };

  const onChangeDate = (_first: any, second: any) => {
    setDate(second);
  };

  const onChangeUser = (
    name: any,
    data: {
      label: string;
      value: string;
      email: string;
      phone: string;
    },
  ) => {
    setIndentify(data?.value);
    setFieldValue(name, { label: name, value: data?.label });
  };

  return (
    <Container>
      <HeaderContainer>
        <img src={consultationIcon} alt="header logo" />
        <TextElem
          color="textPrimary"
          type="medium"
          size="medium"
          tid="CONSULTATION.HEADER"
        />
      </HeaderContainer>

      <SelectContainer>
        <FilterButton onClick={() => toggleOpenFilter()}>
          <TextElem
            color="textSecondary"
            tid="CONSULTATION.FILTER.FILTRATION"
          />
        </FilterButton>
        <StyledSelect
          isOpen={isOpenFilter}
          backgroundColor="white"
          isClearable
          dynamic
          filterOption={() => true}
          onInputChange={handleSelectUserChange}
          placeholder="CONSULTATION.FILTER.USERS"
          options={userList}
          errorMessage={getFieldError(FORM_VALUE_ENUM.USERS)}
          error={isFieldError(FORM_VALUE_ENUM.USERS)}
          // value={getFieldValue(FORM_VALUE_ENUM.USERS)}
          value={setValue(userList, FORM_VALUE_ENUM.USERS)}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          name={FORM_VALUE_ENUM.USERS}
          onChange={onChangeUser}
        />

        <StyledSelect
          isOpen={isOpenFilter}
          backgroundColor="white"
          width="176px"
          placeholder="CONSULTATION.FILTER.CHOOSE_STATUS"
          isMulti
          filterOption={() => true}
          name={FORM_VALUE_ENUM.CONSULTATION_STATUS}
          options={CONSULTATION_STATUS_OPTION}
          errorMessage={getFieldError(FORM_VALUE_ENUM.CONSULTATION_STATUS)}
          error={isFieldError(FORM_VALUE_ENUM.CONSULTATION_STATUS)}
          value={getFieldValue(FORM_VALUE_ENUM.CONSULTATION_STATUS)}
          onChange={onChangeSelect}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          customComponents={{ MultiValueContainer: multiValueContainer }}
          isDisabled={isLoading}
        />

        <StyledSelect
          isOpen={isOpenFilter}
          isClearable
          backgroundColor="white"
          width="176px"
          placeholder="CONSULTATION.FILTER.PAYMENT_STATUS"
          filterOption={() => true}
          name={FORM_VALUE_ENUM.PAYMENT_STATUS}
          options={PAYMENT_STATUS_OPTION}
          errorMessage={getFieldError(FORM_VALUE_ENUM.PAYMENT_STATUS)}
          error={isFieldError(FORM_VALUE_ENUM.PAYMENT_STATUS)}
          value={setValue(
            PAYMENT_STATUS_OPTION,
            FORM_VALUE_ENUM.PAYMENT_STATUS,
          )}
          onChange={setFieldValue}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isDisabled={isLoading}
        />
        <FielDateInputElemStyled
          isOpen={isOpenFilter ?? false}
          maxWidth="255px"
          minWidth="255px"
          placeholder={[
            i18n.t('CONSULTATION.CALENDAR.START'),
            i18n.t('CONSULTATION.CALENDAR.END'),
          ]}
          name={FORM_VALUE_ENUM.DATE}
          onChange={onChangeDate}
        />
      </SelectContainer>
    </Container>
  );
};

const FielDateInputElemStyled = styled(FieldDateInputElem)<{ isOpen: boolean }>`
  .ant-picker-panel {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  }

  .ant-picker-focused {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  }

  .ant-picker-outlined {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  }

  .ant-picker-footer {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  }

  .ant-picker-time-panel-cell-selected > .ant-picker-time-panel-cell-inner {
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: none;
    min-width: none;
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;

    ${({ isOpen }) =>
      !isOpen &&
      css`
        display: none;
      `}
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 166px;
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;

  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  max-width: 830px;
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: ${Spacing(3)};

  @media screen and (max-width: 1024px) {
    gap: ${Spacing(2)};
    grid-template-columns: 1fr;
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  }
`;

const StyledSelect = styled(SelectElem)<{
  width?: string;
  isOpen?: boolean;
  backgroundColor: string;
}>`
  & .Select__control {
    width: 100%;
    min-height: 48px;
    max-height: 48px;

    @media screen and (max-width: 1024px) {
      background-color: ${({ theme }) =>
        theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
    }
  }

  .Select__value-container--is-multi {
    max-width: calc(100% - 5px) !important;
  }

  max-width: ${({ width }) => (width ? width : '186px')};
  min-width: ${({ width }) => (width ? width : '186px')};

  @media screen and (max-width: 1024px) {
    width: 100%;
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
    max-width: none;
    min-width: none;

    ${({ isOpen }) =>
      !isOpen &&
      css`
        display: none;
      `}
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1068px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: ${Spacing(6)};
  margin-bottom: ${Spacing(5)};
  align-self: end;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const FilterButton = styled.button`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    width: 100%;
    min-width: 288px;
    padding: ${Spacing(4)} ${Spacing(4)} ${Spacing(3)} ${Spacing(4)};
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  }
`;
