import React, { useState } from 'react';
import { useMutation } from 'react-query';

import { ACTION_ERROR_INTER } from './constant';
import { Component } from './component';
import { updateAction } from './action';
import { DAY_ENUM } from '../schedule-list/constant';

export const Container: React.FC<{
  time?: [string, string];
  day: string;
  id: DAY_ENUM;
  isWeekendDay?: boolean;
}> = ({ time, day, id, isWeekendDay }) => {
  const [isWeekend, setIsWeekend] = useState<boolean | undefined>(isWeekendDay);

  const onSuccess = (data: any) => {};

  const action = useMutation(updateAction, { onSuccess });

  const isSubmitDisabled = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const handleUpdate = (startTime: string, endTime: string) => {
    if (action.isLoading) return;

    return action.mutate({
      day: id,
      startTime,
      endTime,
    });
  };

  const handleSetWeekend = () => {
    if (action.isLoading) return;

    setIsWeekend((prev) => {
      action.mutate({
        day: id,
        isWeekend: !prev,
      });

      return !prev;
    });
  };

  return (
    <Component
      isWeekend={isWeekend}
      handleSetWeekend={handleSetWeekend}
      day={day}
      time={time}
      handleUpdate={handleUpdate}
      isLoading={action.isLoading}
      isSuccess={action.isSuccess}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};
