import React, { useRef } from 'react';

import styled, { css } from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { TextElem } from '../text';
import { PROPS_TYPE } from './constant';
import { i18n } from '../../lib/lang';

export const Elem: React.FC<PROPS_TYPE> = ({
  title,
  error,
  errorMessage,
  onChange,
  onBlur,
  value,
  name,
  type,
  disabled,
  icon,
  placeholder,
  className,
  isDot,
}) => {
  const refIcon = useRef<HTMLDivElement>(null);

  const handleChange = (e: any) => {
    if (!e.target) e.target = {};

    e.target.name = name;
    e.target.value = e.target.value;

    if (onChange) {
      onChange(e);
    }
  };

  const handleBlur = (e: any) => {
    if (!e.target) e.target = {};

    e.target.name = name;
    e.target.value = e.target.value;

    if (onBlur) {
      onBlur(e);
    }
  };

  const iconSize = refIcon?.current?.clientWidth || 40;

  if (disabled) {
    return (
      <Container>
        {title && (
          <TitleContainer>
            <TextElem
              type="light"
              color="textSecondary"
              size="input"
              tid={title}
            />

            {isDot && <Dot />}
          </TitleContainer>
        )}
        <FakeInput>{value}</FakeInput>
      </Container>
    );
  } else {
    return (
      <Container className={className}>
        {title && (
          <TitleContainer>
            <TextElem
              type="light"
              color="textSecondary"
              size="input"
              tid={title}
            />

            {isDot && <Dot />}
          </TitleContainer>
        )}
        <Content>
          <Input
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error}
            type={type}
            value={value}
            // value={inputValue}
            iconSize={iconSize}
            placeholder={placeholder && i18n.t(placeholder)}
            className={className}
          />
          <Icon ref={refIcon}>{icon}</Icon>
        </Content>
        {(error || errorMessage) && (
          <ErrorContainer className="errorContainer">
            <ErrorMessage>
              <TextElem size="alert" color="error">
                {errorMessage}
              </TextElem>
            </ErrorMessage>
          </ErrorContainer>
        )}
      </Container>
    );
  }
};

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const Input = styled.textarea<{
  error?: boolean;
  onIonChange?: Function;
  type?: string;
  iconSize: number;
}>`
  font-family: 'e-Ukraine' !important;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  height: ${Spacing(27)};
  resize: none;
  cursor: pointer;
  :focus-visible {
    outline: none;
  }
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.LIGHT]};
  padding: ${Spacing(4)};
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  line-height: 1.6em;
  text-align: start;
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
  width: 100%;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  transition: all 0.2s;

  &:focus {
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
  }

  ::placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    font-size: 14px !important;
    margin: 0;
    padding: 0;
  }

  &:hover:not(:focus) {
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

    ::placeholder {
      display: none;
    }
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ error }) =>
    error &&
    css`
      /* border-color: ${({ theme }) => theme[COLOR_ENUM.ERROR]} !important; */
    `}
`;

const FakeInput = styled.div`
  font-family: 'e-Ukraine' !important;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.LIGHT]};
  padding: ${Spacing(4)};
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  line-height: 1.6em;
  text-align: start;
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;
  height: 108px;
  opacity: 0.5;
`;

const ErrorMessage = styled.div`
  display: flex;
  justify-content: start;
  gap: ${Spacing(2.5)};
`;

const Content = styled.div`
  position: relative;
  max-height: ${Spacing(27)};
`;

const ErrorContainer = styled.div`
  position: relative;
  line-height: 1em;
`;

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${Spacing(3)};
`;

const Icon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: max-content;
  box-sizing: content-box;
  z-index: 2;
`;
