import styled from 'styled-components';
import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { USER_ROLE } from '../../../data/auth/constant';
import { useHistory } from 'react-router';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { USER_ITEM_DATA_RAW_INTER } from '../../../data/user/constant';
import { ReadMoreElem } from '../../../common/read-more';
import { CATEGORY_ENUM } from '../../profile-psycho-create-category/constant';
import { ReadMoreListElem } from '../../../common/read-more-list';

export const ClientContainer: React.FC<
  | USER_ITEM_DATA_RAW_INTER
  | (any & { comment: string; category: CATEGORY_ENUM }) // Типизировать ответ функции конвертации
> = (data) => {
  const isRequestList = data?.requestList && data?.requestList.length;
  const isMethodList = data?.methodList && data?.methodList.length;

  return (
    <Container>
      <Block>
        <TextElem
          color="textPrimary"
          type="medium"
          size="main"
          lineHeight="1em"
          tid="CONSULTATION.CLIENT.NEED_CONSULTATION"
        />
        <TextElem
          color="textPrimary"
          size="input"
          lineHeight="1.5em"
          tid={data.category as unknown as string}
        />
      </Block>
      <Block>
        <TextElem
          color="textPrimary"
          type="medium"
          size="main"
          lineHeight="1em"
          tid="CONSULTATION.CLIENT.COMMENT"
        />
        {data?.comment && (
          <ReadMoreElem
            isUnderline
            color="textPrimary"
            lineHeight="1.5em"
            textLimit={40}
            text={data.comment}
          />
        )}
        {!data?.comment && (
          <TextElem
            type="regular"
            color="textSecondary"
            size="input"
            tid="USER.PSYCHO.PROFILE.NOT_SET"
          />
        )}
      </Block>
      <Block>
        <TextElem
          color="textPrimary"
          type="medium"
          size="main"
          tid="CONSULTATION.CLIENT.NEED_REQUEST"
        />
        {isRequestList ? (
          <ReadMoreListElem listLimit={6} list={data?.requestList} />
        ) : (
          <TextElem
            type="regular"
            color="textSecondary"
            size="input"
            tid="USER.PSYCHO.PROFILE.NOT_SET"
          />
        )}
      </Block>
      <Block>
        <TextElem
          color="textPrimary"
          type="medium"
          size="main"
          tid="CONSULTATION.CLIENT.NEED_METHOD"
        />
        {isMethodList ? (
          <ReadMoreListElem listLimit={6} list={data?.methodList} />
        ) : (
          <TextElem
            type="regular"
            color="textSecondary"
            size="input"
            tid="USER.PSYCHO.PROFILE.NOT_SET"
          />
        )}
      </Block>
      <Block>
        <TextElem
          color="textPrimary"
          type="medium"
          size="main"
          tid="CONSULTATION.CLIENT.NEED_AGE_GENDER"
        />
        <TextElem
          color="textPrimary"
          size="input"
          tid="CONSULTATION.CLIENT.AGE_GENDER"
          tvalue={{
            gender: data.needGender ? data.needGender : 'Гендер не вказано',
            age: data.needAge ? data.needAge : 'вік не вказаний',
          }}
        />
      </Block>
      <Block>
        <TextElem
          color="textPrimary"
          type="medium"
          size="main"
          tid="CONSULTATION.CLIENT.TIMEZONE"
        />
        <TextElem
          color="textPrimary"
          size="input"
          tid={data.timezone as unknown as string}
        />
      </Block>
    </Container>
  );
};

const CustomListItem = styled.li`
  position: relative;
  padding-left: ${Spacing(3)};

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 4px;
    height: 4px;
    background-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  row-gap: ${Spacing(4)};
  column-gap: ${Spacing(3)};
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: 21px ${Spacing(6)};
  gap: ${Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;
`;
