import styled from 'styled-components';
import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { USER_ROLE } from '../../../data/auth/constant';
import { useHistory } from 'react-router';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { CONSULTATION_DATA_INTER } from '../../../data/consultation/constant';
import { ButtonElem } from '../../../common/button';
import userPhoto from '../../../asset/svg/user/user-photo.svg';
import buttonArrow from '../../../asset/svg/button/arrow-right.svg';

export const UserHeader: React.FC<
  Partial<CONSULTATION_DATA_INTER & { role: USER_ROLE }>
> = ({ user, psychologist, role }) => {
  const history = useHistory();

  const name =
    role === USER_ROLE.PSYCHOLOGIST ? user?.name : psychologist?.name;
  const photo =
    role === USER_ROLE.PSYCHOLOGIST
      ? user?.photo?.url
      : psychologist?.photo?.url;

  const handleRedirect = (id?: string) => {
    history.push(`/psycho-profile/${id}`);
  };


  return (
    <Block>
      <Profile>
        <Photo alt="profile photo" src={photo || userPhoto} />
        <Info>
          <TextElem tid={name} size="main" type="medium" color="textPrimary" />
          <TextElem
            tid={
              role === USER_ROLE.USER
                ? 'CONSULTATION.PSYCHOLOGIST.ROLE'
                : 'CONSULTATION.USER.ROLE'
            }
            size="semiSmall"
            color="textSecondary"
          />
        </Info>
      </Profile>
      {role === USER_ROLE.USER && (
        <InfoContainer>
          <TextElem
            type="regular"
            color="textSecondary"
            size="semiSmall"
            tid="USER.PSYCHO.PROFILE.NAME"
            tvalue={{
              age: psychologist?.age && `${psychologist?.age}`,
              gender: psychologist?.gender && `• ${psychologist?.gender}`,
              timezone: psychologist?.timezone && `• ${psychologist?.timezone}`,
              lgbtFrendly: psychologist?.lgbtFrendly ? '• LGBT+' : '',
            }}
          />

          <ButtonStyled
            type="button"
            onClick={() => handleRedirect(psychologist?.id)}
          >
            <TextElem
              size="main"
              type="medium"
              color="default"
              tid="CONSULTATION.USER.PROFILE_BUTTON"
            />
            <img src={buttonArrow} alt="arrow" />
          </ButtonStyled>
        </InfoContainer>
      )}
    </Block>
  );
};

const InfoContainer = styled.div`
  gap: ${Spacing(5)};
  align-items: center;
  display: flex;
  white-space: nowrap;
  justify-content: end;
  width: 100%;
`;

const ButtonStyled = styled(ButtonElem)`
  display: flex;
  gap: ${Spacing(2)};
  padding: 17px;
  border-radius: ${Spacing(2)};
  max-width: ${Spacing(47)};
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Photo = styled.img`
  width: ${Spacing(16)};
  height: ${Spacing(16)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  object-fit: cover;
`;

const Block = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(4)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;
`;

const Profile = styled.div`
  display: flex;
  gap: ${Spacing(4)};
  align-items: center;
  width: 100%;
`;
