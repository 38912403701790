import React from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';

import { Spacing } from '../../theme';
import { AlertEmptyElem } from '../../common/alert-empty';

import { COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../../common/text';
// import { IonInfiniteScroll } from '@ionic/react';
import { SkeletonListElem } from '../../common/skeleton-list';
import {
  CONSULTATION_ITEM_DATA_INTER,
  CONSULTATION_ITEM_LIST_DATA_INTER,
} from './constant';
import { CardElem } from '../../common/card/index';
import { Card } from './frame/card';
import { Category } from '../profile-psycho/frame/category';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { IonInfiniteScroll } from '@ionic/react';

export const Component: React.FC<{
  data?: CONSULTATION_ITEM_LIST_DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isFetching?: boolean;
  fetchNextPage: Function;
  isIdle?: boolean;
}> = ({
  data,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  isFetching,
  fetchNextPage,
  isIdle,
}) => {
  return (
    <>
      {/* <GridElem spacing={10}> */}
      {isError && <AlertActionElem text={errorMessage} />}
      {data && isSuccess && data.isEmpty && <AlertEmptyElem />}
      <Container>
        <GridStyled>
          {data && !data.isEmpty && (
            <>
              <Head>
                <TextElem
                  oneLine
                  tid="CONSULTATION.LIST.HEAD.CREATE_DATE"
                  color="textSecondary"
                  size="semiSmall"
                />
                <TextElem
                  oneLine
                  tid="CONSULTATION.LIST.HEAD.CONDUCT_DATE"
                  color="textSecondary"
                  size="semiSmall"
                />
                <TextElem
                  oneLine
                  tid="CONSULTATION.LIST.HEAD.CATEGORY"
                  color="textSecondary"
                  size="semiSmall"
                />
                <TextElem
                  oneLine
                  tid="CONSULTATION.LIST.HEAD.PRICE_AND_DURATION"
                  color="textSecondary"
                  size="semiSmall"
                />
                <TextElem
                  oneLine
                  tid="CONSULTATION.LIST.HEAD.CLIENT"
                  color="textSecondary"
                  size="semiSmall"
                />
                <TextElem
                  oneLine
                  tid="CONSULTATION.LIST.HEAD.PSYCHOLOGIST"
                  color="textSecondary"
                  size="semiSmall"
                />
                <TextElem
                  oneLine
                  tid="CONSULTATION.LIST.HEAD.PAYMENT_STATUS"
                  color="textSecondary"
                  size="semiSmall"
                />
              </Head>
              {isSuccess &&
                !isFetching &&
                data?.list.map((item: CONSULTATION_ITEM_DATA_INTER) => (
                  <Card {...item} />
                ))}
            </>
          )}
        </GridStyled>
        {isFetching && !data?.isEmpty && (
          <SkeletonWrapper>
            <SkeletonListElem />
          </SkeletonWrapper>
        )}

        {/* {data &&
          data.list &&
          data.list.length > 39 &&
          !data.isEmpty &&
          !isFetching && (
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                fetchNextPage();
                setTimeout(() => ev.target.complete(), 500);
              }}
            >
              {!isIdle && <PaymentSkeletonElem />}
            </IonInfiniteScroll>
          )} */}
      </Container>
      {/* </GridElem> */}
    </>
  );
};

const SkeletonWrapper = styled.div`
  margin-top: ${Spacing(4)};
`;

const Container = styled.div`
  /* overflow-x: auto; */
  scrollbar-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]}
    transparent;
  scrollbar-width: thin;
  width: 100%;

  // @media screen and (max-width: 900px){

  // display: grid;
  // background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  // grid-template-columns: 270px auto;
  // grid-template-rows: '92px auto;
  // padding-bottom: ${Spacing(10)};

  // background-image: url(backgroundPrimary) !important;
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  // }
`;

const GridStyled = styled(GridElem)`
  width: 1130px;
  // gap: 0;
  column-gap: ${Spacing(
    11,
  )}; // число просто підібрав , але проблемавв  тому що в колонках різні відступи. а якщо не задавати цю відстань то тоді контейнер має замалу ширину
  // column-gap: 32px 56px 56px 56px 28px 36px;
  // grid-template-columns: repeat(7, 1fr);

  grid-template-columns: 107px 137px 122px 91px 126px 126px 112px; // для першої додано 2px, і до останньої 1px , і для обох + Padding 20px
  row-gap: ${Spacing(3)};

  span,
  a {
    line-height: 14px;
  }
  // padding: 0 ${Spacing(5)};

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const Head = styled(GridElem)`
  display: none;

  @media screen and (min-width: 900px) {
    display: block;
    height: 56px;
    padding: ${Spacing(5)};
    width: 100%;
    align-items: center;
    display: grid;
    grid-column: span 7;
    grid-template-columns: 107px 137px 122px 134px 126px 126px 112px;
    // grid-template-columns: subgrid;
    // grid-template-columns: repeat(6, 1fr) auto;
    justify-content: start;
    gap: ${Spacing(7)};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

    & > :last-child {
      text-align: center;
    }
  }
`;
