import styled from 'styled-components';
import { Spacing } from '../../theme';
import { USER_ROLE } from '../../data/auth/constant';
import { getMenuItems } from '../desktop-menu-app/constant';
import { CardMobile } from './frame/card-mobile';
import { COLOR_ENUM } from '../../theme/color';

export const Component: React.FC<{
  role: USER_ROLE;
  isActivePath: Function;
}> = ({ role, isActivePath }) => {
  const menuItems = getMenuItems(role);

  return (
    <Container>
      {menuItems
        .filter((item) => item.isVisible)
        .map(({ title, icon, iconActive, path }) => (
          <CardMobile
            key={path}
            href={path}
            title={title}
            icon={icon}
            iconActive={iconActive}
            isActive={isActivePath(path)}
          />
        ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: ${Spacing(1)};
  justify-content: space-around;
  align-items: center;
`;
