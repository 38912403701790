import styled from 'styled-components';

import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { CONSULTATION_DATA_INTER } from '../../../data/consultation/constant';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';

export const InforamationContainer: React.FC<
  Partial<CONSULTATION_DATA_INTER>
> = ({
  timeView,
  dayView,
  duration,
  price,
  psychologist,
  payment,
  paymentDayView,
  paymentView,
  paymentTimeView,
}) => {
  return (
    <>
      <Block>
        <Column>
          <TitleStyled
            tid="CONSULTATION.ADMIN.TITLE"
            type="medium"
            color="textPrimary"
            size="main"
          />
          <Item>
            <TextElem
              tid="CONSULTATION.ADMIN.DATE"
              type="light"
              color="textSecondary"
              size="input"
            />
            <TextElem
              tid={`${dayView} о ${timeView}`}
              type="medium"
              color="textPrimary"
              size="main"
            />
          </Item>
          <Item>
            <TextElem
              tid="CONSULTATION.ADMIN.DURATION_TITLE"
              type="light"
              color="textSecondary"
              size="input"
            />
            <TextElem
              tid="CONSULTATION.ADMIN.DURATION"
              tvalue={{ value: String(duration) }}
              type="medium"
              color="textPrimary"
              size="main"
            />
          </Item>

          <Item>
            <TextElem
              tid="CONSULTATION.ADMIN.PRICE_TITLE"
              type="light"
              color="textSecondary"
              size="input"
            />
            <TextElem
              tid="CONSULTATION.ADMIN.PRICE"
              tvalue={{ value: String(price) }}
              type="medium"
              color="textPrimary"
              size="main"
            />
          </Item>

          {psychologist?.url && (
            <Item>
              <TextElem
                tid="CONSULTATION.ADMIN.URL_TITLE"
                type="light"
                color="textSecondary"
                size="input"
              />
              <TextElemStyled
                onClick={() => {
                  window.open(psychologist?.url, '_blank');
                }}
                tid="CONSULTATION.ADMIN.URL"
                tvalue={{ value: psychologist?.url }}
                type="medium"
                color="default"
                size="main"
              />
            </Item>
          )}
        </Column>
        {payment && (
          <Column>
            <TitleStyled
              tid="CONSULTATION.ADMIN.PAYMENT.TITLE"
              type="medium"
              color="textPrimary"
              size="main"
            />
            <Item>
              <TextElem
                tid="CONSULTATION.ADMIN.DATE"
                type="light"
                color="textSecondary"
                size="input"
              />
              <TextElem
                tid={`${paymentDayView} ${paymentTimeView}`}
                type="medium"
                color="textPrimary"
                size="main"
              />
            </Item>
            <Item>
              <TextElem
                tid="CONSULTATION.ADMIN.PAYMENT.MANUAL"
                type="light"
                color="textSecondary"
                size="input"
              />
              <TextElem
                tid={paymentView}
                type="medium"
                color="textPrimary"
                size="main"
              />
            </Item>
          </Column>
        )}
      </Block>
    </>
  );
};

const TitleStyled = styled(TextElem)`
  margin-bottom: ${Spacing(2)};
`;

const TextElemStyled = styled(TextElem)`
  text-decoration: underline;

  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  max-width: ${Spacing(70)};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
`;

const Block = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 90px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(6)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;
`;
