import React from 'react';
import styled from 'styled-components';

import { DATA_INTER } from '../constant';
import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text';
import { PreviewFile } from './preview-file';

export const FileComponent: React.FC<{ data?: DATA_INTER }> = ({ data }) => {
  return (
    <Container>
      <InfoContainer>
        <TextElemStyled
          type="medium"
          color="textPrimary"
          size="main"
          tid="USER.PSYCHO.PROFILE.CERTIFICATE"
        />
        <FileContainer>
          <SliderContainer>
            {data?.certificate && data?.certificate.length > 0 ? (
              data.certificate.map((e) => <PreviewFile {...e} />)
            ) : (
              <TextElemStyled
                type="medium"
                color="textPrimary"
                size="main"
                tid="USER.PSYCHO.PROFILE.NOT_SET"
              />
            )}
          </SliderContainer>
        </FileContainer>
      </InfoContainer>
      <InfoContainer>
        <TextElemStyled
          type="medium"
          color="textPrimary"
          size="main"
          tid="USER.PSYCHO.PROFILE.DIPLOMA"
        />
        <FileContainer>
          <SliderContainer>
            {data?.diploma && data?.diploma.length > 0 ? (
              data.diploma.map((e) => <PreviewFile key={e.id} {...e} />)
            ) : (
              <TextElemStyled
                type="medium"
                color="textPrimary"
                size="main"
                tid="USER.PSYCHO.PROFILE.NOT_SET"
              />
            )}
          </SliderContainer>
        </FileContainer>
      </InfoContainer>
    </Container>
  );
};

const TextElemStyled = styled(TextElem)`
  @media screen and (max-width: 900px) {
    font-size: 15px;
  }
`;

const FileContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  max-width: ${Spacing(105)};
  flex-wrap: wrap;
  overflow: hidden;
  overflow-x: auto;

  @media screen and (max-width: 1100px) {
    max-width: unset;
  }

  @media screen and (max-width: 900px) {
    flex-wrap: nowrap;
  }
`;

const SliderContainer = styled.div`
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  gap: ${Spacing(2)};
  flex-wrap: nowrap;
  min-width: ${Spacing(105)};
`;

const Container = styled.div`
  display: flex;
  gap: ${Spacing(3)};

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  flex-direction: column;
  display: flex;
  gap: ${Spacing(4)};
  padding: ${Spacing(7)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};

  @media screen and (max-width: 900px) {
    padding: ${Spacing(5)};
  }
`;
