import { USER_ROLE } from '../../data/auth/constant.js';
import { duration } from 'moment';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';

export const MODULE_NAME = 'CONSULTATION_LIST_ADMIN_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (pageParam: string) => `/consultation/admin-list/?${pageParam}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface PAYMENT_ITEM_DATA_INTER {
  isPaid: boolean | null;
}
export interface CONSULTATION_DATA_RAW_INTER {
  id: string;
  createDate: string;
  updateDate: string;
  price: string;
  status: string;
  payment: PAYMENT_ITEM_DATA_INTER | null;
  date: string;
  comment: string;
  duration: string;
  category: CATEGORY_ENUM;
  psychologist: { name: string; email: string };
  user: { email: string };
}

export interface CONSULTATION_ITEM_DATA_INTER
  extends Omit<CONSULTATION_DATA_RAW_INTER, 'category' | 'payment'> {
  category: string;
  paymentStatus: 'PAID' | 'NOT_PAID';
  time: string;
}
export interface CONSULTATION_ITEM_LIST_DATA_INTER {
  list: CONSULTATION_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export interface FILTER_OPTION {
  paymentStatus?: 'paid' | 'not_paid';
  consultationStatus?: string;
  indentify?: string;
  startDate?: string;
  endDate?: string;
}
