import { convertDay } from '../../lib/util/dateConvert';
import { WEEKEND_SCHEDULE_DATA, WEEKEND_SCHEDULE_RAW } from './constant';

export const convert = (
  schedule: WEEKEND_SCHEDULE_RAW,
): WEEKEND_SCHEDULE_DATA[] => {
  return Object.keys(schedule).map((key) => {
    const { day } = convertDay(key);

    const time: [string, string] = [schedule[key][0], schedule[key][1]];

    const convert = {
      id: key,
      day,
      time: time,
    };
    return convert;
  });
};
