import styled from 'styled-components';
import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text';
import deleteIcon from '../../../asset/svg/button/delete-weekend.svg';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';

export const WeekendInfoItem: React.FC<{
  startTime: string;
  endTime: string;
  day: string;
  deleteAction: () => void;
}> = ({ day, startTime, endTime, deleteAction }) => {
  return (
    <Container>
      <TextElem size="semiSmall" color="textPrimary" tid={day} />
      <TimeContainer>
        <TextElem
          tid="SCHEDULE.WEEKEND.LIST.START_TIME"
          size="semiSmall"
          type="light"
          color="textSecondary"
        />
        <TextElem
          tid="SCHEDULE.WEEKEND.LIST.START_TIME_VALUE"
          tvalue={{ value: startTime }}
          size="semiSmall"
          color="textPrimary"
        />
      </TimeContainer>
      <TimeContainer>
        <TextElem
          tid="SCHEDULE.WEEKEND.LIST.END_TIME"
          size="semiSmall"
          type="light"
          color="textSecondary"
        />
        <TextElem
          tid="SCHEDULE.WEEKEND.LIST.END_TIME_VALUE"
          tvalue={{ value: endTime }}
          size="semiSmall"
          color="textPrimary"
        />
      </TimeContainer>
      <ButtonStyled onClick={deleteAction}>
        <DeleteImg src={deleteIcon} alt="close menu" />
      </ButtonStyled>
    </Container>
  );
};

const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: unset;
  /* padding: ${Spacing(1)}; */

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const DeleteImg = styled.img`
  width: 18px;
  height: 18px;
`;

const TimeContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px ${Spacing(4)};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  align-items: center;
`;
