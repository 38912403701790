import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { ACTION_ERROR_INTER, WEEKEND_PAYLOAD_INTER } from './constant';
import { Component } from './component';
import { updateAction } from './action';
import { CONSULTATION_USER_MODULE_NAME } from '../consultation-user-item';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { generateDateList } from '../../lib/util/generateDateList';
import { WEEKEND_LIST_MODULE_NAME } from '../weekend-list';

export const Container: React.FC = () => {
  const queryClient = useQueryClient();
  const [isAddWeekend, setIsAddWeekend] = useState(false);

  const [payload, setPayload] = useState<WEEKEND_PAYLOAD_INTER>({});

  const handleAddForm = () => {
    setIsAddWeekend(true);
  };

  const onSuccess = () => {
    setIsAddWeekend(false);
    queryClient.invalidateQueries(WEEKEND_LIST_MODULE_NAME);
  };

  const action = useMutation(updateAction, { onSuccess });

  const isSubmitDisabled = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const handleSuccess = () => {
    action.mutate(payload);
  };

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const timezone = auth.user ? auth.user.timezone : null;

  const dateOption = generateDateList(timezone);

  const updatePayload = (name: keyof WEEKEND_PAYLOAD_INTER, value: string) => {
    setPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Component
      updatePayload={updatePayload}
      dateOption={dateOption}
      handleAddForm={handleAddForm}
      isAddWeekend={isAddWeekend}
      handleSuccess={handleSuccess}
      isLoading={action.isLoading}
      isSuccess={action.isSuccess}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};
