import React from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import { FORM_VALUE_ENUM } from './constant';
import { TextElem } from '../../common/text';
import { DoubleContainerElem } from '../../common/double-container';
import closeIcon from '../../asset/svg/common/close.svg';
import { SelectElem } from '../../common/select';
import { USER_ROLE_OPTION_LIST } from '../../data/user/constant';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  close: Function;
  setValue: Function;
  setFieldValue: Function;
  onSubmit: () => void;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  close,
  setFieldValue,
  setValue,
  onSubmit,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <GridElem spacing={5}>
        {/* <TitleContainer>
          <TextElem tid="USER.UPDATE.TITLE" type="semi-bold" size="main" />
          <Icon src={closeIcon} onClick={() => close()} />
        </TitleContainer> */}
        <UpdateContainer>
          <FieldTextElem
            name={FORM_VALUE_ENUM.EMAIL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="USER.UPDATE.FORM.EMAIL"
            value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
            error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
          />
          <SelectElem
            name={FORM_VALUE_ENUM.ROLE}
            onChange={setFieldValue}
            options={USER_ROLE_OPTION_LIST}
            title="USER.UPDATE.FORM.ROLE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.ROLE)}
            error={isFieldError(FORM_VALUE_ENUM.ROLE)}
            value={setValue(USER_ROLE_OPTION_LIST, FORM_VALUE_ENUM.ROLE)}
          />
          <ButtonElem
            onClick={() => {
              onSubmit();
            }}
            type="submit"
            tid="USER.UPDATE.BUTTON"
            // fill="solid"
            disabled={isSubmitDisabled()}
            color="success"
          />
        </UpdateContainer>
        {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
        {isSuccess && (
          <AlertActionElem type="success" tid="USER.ADMIN.CHANGE_ROLE" />
        )}
      </GridElem>
    </FormElem>
  );
};

const UpdateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: ${Spacing(3)};
  align-items: end;
`;
