import React from 'react';
import styled from 'styled-components';

import { TextElem } from '../text';
import { Spacing } from '../../theme';

export const Elem: React.FC<{
  titleTid: string;
  prefixTid?: string;
  sufixTid?: string;
  postfixTid?: string;
}> = ({ titleTid, prefixTid, sufixTid, postfixTid }) => {
  return (
    <TextWrapper>
      <TextElem
        size="medium"
        color="textPrimary"
        type="medium"
        tid={titleTid}
      />
      <TitleContainer>
        <TextStyled
          size="semiSmall"
          color="textSecondary"
          type="regular"
          tid={prefixTid}
        />
        <TextContainer>
          <TextStyled
            size="semiSmall"
            color="textSecondary"
            type="regular"
            tid={sufixTid}
          />
          {'  '}

          <TextStyled
            oneLine
            size="semiSmall"
            color="textPrimary"
            type="medium"
            tid={postfixTid}
          />
        </TextContainer>
      </TitleContainer>
    </TextWrapper>
  );
};

const TextWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
`;
const TextStyled = styled(TextElem)`
  line-height: 1.7em;
`;
const TitleContainer = styled.div`
  text-align: center;
`;
