import styled from 'styled-components';

import { TextElem } from '../../common/text';
import { SIZE_FONT_DATA, SIZE_FONT_ENUM } from '../../theme/size';
import backgroundImage from '../../asset/background.jpg';
import { Spacing } from '../../theme';

export const Component: React.FC<{}> = ({}) => {
  return (
    <Container>
      <TextElem
        color="textSecondary"
        size="semiSmall"
        type="medium"
        tid="ERROR.APP.TITLE"
      />
      <TextElemStyled size="heading" type="medium" tid="ERROR.APP.INFO" />
    </Container>
  );
};

const TextElemStyled = styled(TextElem)`
  @media screen and (max-width: 900px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
  }
`;

const Container = styled.div`
  text-align: center;
  background-image: url(${backgroundImage}) !important;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${Spacing(10)} ${Spacing(5)};
`;
